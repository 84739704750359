import React, { useEffect, useState } from "react";
import { ModalContextProvider } from "./contexts/ModalContext";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./walletConfig";
import { WrappedRainbowKitProvider } from "@/contexts/WrappedRainbowKitProvider";
import { BridgeSocketProvider } from "./contexts/BridgeSocket";
import { ChainInitiationContextProvider } from "./contexts/ChainInitiationContext";
var queryClient = new QueryClient();
export var AppContext = function (_a) {
    var children = _a.children;
    var _b = useState(false), isKoteWebInitialized = _b[0], setIsKoteWebInitialized = _b[1];
    useEffect(function () {
        if (window.koteWeb) {
            setIsKoteWebInitialized(true);
        }
        else {
            var checkKoteWeb_1 = function () {
                if (window.koteWeb) {
                    setIsKoteWebInitialized(true);
                }
                else {
                    setTimeout(checkKoteWeb_1, 100);
                }
            };
            checkKoteWeb_1();
        }
    }, []);
    if (!isKoteWebInitialized) {
        return null; // or a loading indicator
    }
    return (React.createElement(WagmiProvider, { config: config },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(WrappedRainbowKitProvider, null,
                React.createElement(ChakraProvider, { theme: theme },
                    React.createElement(BridgeSocketProvider, null,
                        React.createElement(ModalContextProvider, null,
                            React.createElement(ChainInitiationContextProvider, null, children))))))));
};
