var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import { Modal } from "@/components/Modal";
import { useBridgeSocket } from "@/contexts/BridgeSocket";
import { useAuthToken } from "@/hooks/useAuthToken";
import { GearList } from "@/components/gear/GearList";
import { RadioButton } from "@/components/input/RadioButton";
import { useBridgeGearInput } from "@/hooks/useBridgeGearInput";
import { useWithdraw } from "@/hooks/useWithdraw";
import { useDeposit } from "@/hooks/useDeposit";
import { useGearSubgraph } from "@/hooks/useGearSubgraph";
import { useSwitchChain } from "wagmi";
import { getGearDefinition } from "../../gear";
import { useAccount } from "wagmi";
import { NETWORK } from "@/env";
import { FragmentTab } from "@/components/modals/Fragments";
export var BridgeGearModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, openAuthModal = _a.openAuthModal, onRefresh = _a.onRefresh;
    var _b = useState("withdraw"), tab = _b[0], setTab = _b[1];
    var token = useAuthToken()[0];
    var _c = useState("BP1"), selectedBP = _c[0], setSelectedBP = _c[1];
    var handleBPToggle = function (bpIndex) {
        console.log("Switched to BP index: ".concat(bpIndex));
        // You can add any additional logic here if needed
    };
    function getNetworkId() {
        if (NETWORK === "mainnet") {
            return 42161; // Arbitrum
        }
        else if (NETWORK === "testnet") {
            return 421614; // Arbitrum Sepolia
        }
        else {
            throw new Error("Unsupported network: ".concat(NETWORK));
        }
    }
    var chain = useAccount().chain;
    var networkId = getNetworkId();
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, contentProps: {
            maxWidth: "960px",
            padding: 0,
        } },
        React.createElement(Flex, { align: "center", justify: "space-between", flexShrink: 0, padding: "20px 30px", borderBottomWidth: 1, borderColor: "blackAlpha.600", gap: 2, wrap: "wrap" },
            React.createElement(Flex, { align: "center" },
                React.createElement(Text, { fontSize: "2xl", fontWeight: "bold", color: "whiteAlpha.700", mr: 4 }, "Gear Bridge")),
            React.createElement(RadioButton, { value: tab, onSelect: setTab, options: [
                    { value: "withdraw", label: "Withdraw" },
                    { value: "deposit", label: "Deposit" },
                    { value: "fragments", label: "Fragments" },
                ] })),
        React.createElement(Button, { style: {
                width: "100%",
                display: "flex",
                backgroundColor: "rgba(0, 0, 0, 0.10)",
            }, color: "white", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: onRefresh }, "Refresh"),
        tab === "withdraw" && (React.createElement(Withdraw, { openAuthModal: openAuthModal, isOpen: isOpen, onClose: onClose, onRefresh: onRefresh, chain: chain, networkId: networkId })),
        tab === "deposit" && (React.createElement(Deposit, { isOpen: isOpen, onClose: onClose, onRefresh: onRefresh, chain: chain, networkId: networkId })),
        tab === "fragments" && token && (React.createElement(FragmentTab, { jwtToken: token, onClose: onClose }))));
};
var Withdraw = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, openAuthModal = _a.openAuthModal, onRefresh = _a.onRefresh, chain = _a.chain, networkId = _a.networkId;
    var token = useAuthToken()[0];
    var _b = useBridgeSocket(), isAuthenticated = _b.isAuthenticated, login = _b.login, fetchItems = _b.fetchItems, gameGear = _b.items, totalGearCount = _b.unfilteredItemCount;
    var _c = useBridgeGearInput(gameGear), gearInput = _c.input, selectGear = _c.select;
    var _d = useWithdraw(), socketWithdraw = _d.withdraw, isSuccess = _d.isSuccess;
    var withdrawls = useBridgeSocket().withdrawls;
    var switchChain = useSwitchChain().switchChain;
    useEffect(function () {
        console.log("gear in withdraw", gameGear);
    }, [gameGear]);
    var aggregatedWithdrawals = useMemo(function () {
        return withdrawls === null || withdrawls === void 0 ? void 0 : withdrawls.reduce(function (acc, _a) {
            var _b = _a.withdrawl, id = _b.id, amount = _b.amount;
            if (acc[id]) {
                acc[id] += amount;
            }
            else {
                acc[id] = amount;
            }
            return acc;
        }, {});
    }, [withdrawls]);
    var totalSelected = useMemo(function () {
        var total = 0;
        Object.values(gearInput).forEach(function (_a) {
            var amount = _a.amount;
            return (total += amount);
        });
        return total;
    }, [gearInput]);
    var totalAvailable = useMemo(function () {
        var total = 0;
        gameGear === null || gameGear === void 0 ? void 0 : gameGear.forEach(function (_a) {
            var amount = _a.amount;
            return (total += amount);
        });
        return total;
    }, [gameGear]);
    var totalPendingItems = useMemo(function () {
        var total = 0;
        withdrawls === null || withdrawls === void 0 ? void 0 : withdrawls.forEach(function (_a) {
            var amount = _a.withdrawl.amount;
            return (total += amount);
        });
        return total;
    }, [withdrawls]);
    useEffect(function () {
        console.log("Displayed Withdraw Gear IDs:", gameGear);
    }, [gameGear]);
    useEffect(function () {
        if (isSuccess) {
            console.log("Closing due to isSuccess in Withdraw");
            onClose();
        }
    }, [isSuccess]);
    // Authenticate
    useEffect(function () {
        if (isOpen && !isAuthenticated && token) {
            login(token);
        }
    }, [isOpen, token, isAuthenticated]);
    // Fetch gear
    useEffect(function () {
        if (isOpen && isAuthenticated) {
            fetchItems();
            console.log("fetched items");
        }
    }, [isOpen, gameGear, isAuthenticated]);
    var withdraw = function () {
        var itemAmounts = [];
        console.log("gearInput before passing to socketWithdraw", gearInput);
        console.log("here is gi", gearInput);
        Object.keys(gearInput).forEach(function (idString) {
            var id = Number(idString);
            var _a = gearInput[id], amount = _a.amount, fullGearId = _a.fullGearId; // Destructure to get amount and fullGearId
            itemAmounts.push({ id: id, amount: amount, fullGearId: fullGearId }); // Assuming GearQuantity accepts fullGearId
        });
        console.log("Passing itemAmounts to socketWithdraw", itemAmounts);
        socketWithdraw(itemAmounts);
    };
    var handleSwitchNetwork = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Attempting to switch network...");
                    if (!switchChain) return [3 /*break*/, 2];
                    console.log("Switch network function is available. Switching network...");
                    console.log("Network ID:", networkId);
                    return [4 /*yield*/, switchChain({ chainId: networkId })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    console.error("switchNetwork function is undefined");
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    console.log("isAuthenticated", isAuthenticated);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { flexGrow: 1, overflowY: "auto", maxH: "calc(100vh - 375px)", p: "10px" }, !token ? (React.createElement(Flex, { flexDir: "column", align: "center" },
            React.createElement(Text, { color: "gray.500" }, "You must be signed in to bridge gear"),
            React.createElement(Button, { onClick: openAuthModal, mt: 3 }, "Login"))) : !isAuthenticated ? (React.createElement(Flex, { flexDir: "column", align: "center" },
            React.createElement(Text, { color: "gray.500" }, "Connect to the bridging server"),
            React.createElement(Button, { onClick: function () { return login(token); }, mt: 3 }, "Connect"))) : (gameGear === null || gameGear === void 0 ? void 0 : gameGear.length) === 0 ? (React.createElement(Flex, { p: 10, gap: 4, direction: "column", align: "center" },
            React.createElement(Text, { align: "center", color: "gray.400" }, "You don't have any withdrawable in game gear."),
            totalGearCount > 0 && (React.createElement(Text, { align: "center", color: "gray.600" },
                "You have ",
                totalGearCount,
                " piece",
                totalGearCount === 1 ? "" : "s",
                " ",
                "of unbridgeable (Common/Uncommon) gear.")))) : gameGear ? (React.createElement(GearList, { gear: gameGear, gearInput: gearInput, selectGear: selectGear })) : (React.createElement(Text, { color: "gray.500", align: "center" }, "Loading..."))),
        React.createElement(Footer, null,
            React.createElement(Text, { color: "whiteAlpha.600", fontWeight: "semibold" },
                totalSelected,
                " / ",
                totalAvailable),
            React.createElement(Text, { color: "whiteAlpha.600", fontWeight: "semibold" }, totalPendingItems > 0
                ? "Withdraw to complete ".concat(totalPendingItems, " pending items")
                : "No pending withdrawals"),
            React.createElement(Flex, { align: "center", p: 2 },
                React.createElement("div", { style: {
                        display: "flex",
                        overflowX: "auto",
                        maxWidth: "calc(75px * 3)",
                    } }, Object.entries(aggregatedWithdrawals || {}).map(function (_a) {
                    var idString = _a[0], amount = _a[1];
                    var id = Number(idString);
                    var image = getGearDefinition(id).image;
                    return (React.createElement(Flex, { align: "center", key: id, mr: 4 },
                        React.createElement(Image, { src: image, boxSize: "25px", m: 2 }),
                        React.createElement(Text, { mr: 2 },
                            "x",
                            Number(amount))));
                })),
                React.createElement(Button, { bg: "green.500", color: "black", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: chain && chain.id === networkId ? withdraw : handleSwitchNetwork }, chain && chain.id === networkId ? "Withdraw" : "Switch Networks")))));
};
var Deposit = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onRefresh = _a.onRefresh, chain = _a.chain, networkId = _a.networkId;
    var address = useAccount().address;
    console.log("Address from useAccount:", address); // Add this line
    var chainGear = useGearSubgraph(address).data;
    console.log("chainGear", chainGear);
    var switchChain = useSwitchChain().switchChain;
    var disaggregateGearId = function (gearId) {
        var strGearId = gearId.toString();
        var levelStr = "";
        var i = 0;
        while (i < strGearId.length && strGearId[i] === "0") {
            i++;
        }
        while (i < strGearId.length && strGearId[i] !== "0") {
            levelStr += strGearId[i];
            i++;
        }
        var id = parseInt(strGearId.substring(i), 10);
        var level10Fix = strGearId.startsWith("10000");
        var level = levelStr === "" ? 1 : level10Fix ? 10 : parseInt(levelStr, 10);
        return { level: level, id: id };
    };
    function aggregateGearId(level, id) {
        if (level < 1 || level > 12) {
            throw new Error("Level must be between 1 and 12");
        }
        var levelStr = level.toString().padStart(2, "0");
        var newGearId = parseInt(levelStr + "000" + id.toString(), 10);
        return newGearId;
    }
    var chainGearQuantities = (chainGear === null || chainGear === void 0 ? void 0 : chainGear.map(function (_a) {
        var tokenId = _a.tokenId, amount = _a.amount;
        console.log("Checking tokenId", tokenId);
        var needsDisaggregation = tokenId > 100000; // Assuming 100000 as the threshold
        if (needsDisaggregation) {
            var _b = disaggregateGearId(tokenId), id = _b.id, level = _b.level; // Now includes level
            console.log("disaggregated data", id, level);
            // Use tokenId directly as fullGearId when needsDisaggregation is true
            return { id: id, amount: amount, level: level, fullGearId: tokenId };
        }
        else {
            console.log("Returning default level 1");
            // Use aggregateGearId to create fullGearId with level 1
            var fullGearId = aggregateGearId(1, tokenId);
            return { id: tokenId, amount: amount, level: 1, fullGearId: fullGearId };
        }
    }).filter(function (_a) {
        var amount = _a.amount;
        return amount > 0;
    })) || [];
    var _b = useBridgeGearInput(chainGearQuantities), gearInput = _b.input, selectGear = _b.select;
    console.log("gearInput in depositiemsinput", gearInput);
    var depositItemsInput = useMemo(function () {
        return Object.entries(gearInput).map(function (_a) {
            var idString = _a[0], amount = _a[1].amount;
            var fullGearId = (chainGearQuantities.find(function (_a) {
                var id = _a.id;
                return id === Number(idString);
            }) || {}).fullGearId;
            return {
                id: Number(idString),
                amount: amount,
                fullGearId: fullGearId,
            };
        });
    }, [gearInput]);
    useEffect(function () {
        console.log("Displayed Deposit Gear IDs:", chainGearQuantities);
    }, [chainGearQuantities]);
    var _c = useDeposit(depositItemsInput), depositItemsContractCall = _c.depositItemsContractCall, isSuccess = _c.isSuccess;
    var totalSelected = useMemo(function () {
        var total = 0;
        Object.values(gearInput).forEach(function (_a) {
            var amount = _a.amount;
            return (total += amount);
        }); // Correctly access the amount property
        return total;
    }, [gearInput]);
    var totalAvailable = useMemo(function () {
        var total = 0;
        chainGear === null || chainGear === void 0 ? void 0 : chainGear.forEach(function (_a) {
            var amount = _a.amount;
            return (total += amount);
        });
        return total;
    }, [chainGear]);
    useEffect(function () {
        if (isSuccess) {
            console.log("Closing due to isSuccess in Deposit");
            onClose();
        }
    }, [isSuccess]);
    var handleSwitchNetwork = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Attempting to switch network...");
                    if (!switchChain) return [3 /*break*/, 2];
                    console.log("Switch network function is available. Switching network...");
                    console.log("Network ID:", networkId);
                    return [4 /*yield*/, switchChain({ chainId: networkId })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    console.error("switchNetwork function is undefined");
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { flexGrow: 1, overflowY: "auto", maxH: "calc(100vh - 200px)", p: "10px" }, !chainGear ? (React.createElement(Text, { p: 10, align: "center", color: "gray.600" }, "Loading...")) : chainGearQuantities.length === 0 ? (React.createElement(Flex, { p: 10, gap: 4, direction: "column", align: "center" },
            React.createElement(Text, { align: "center", color: "gray.400" }, "You don't have any depositable on chain gear."))) : (React.createElement(GearList, { gear: chainGearQuantities, gearInput: gearInput, selectGear: selectGear }))),
        React.createElement(Footer, null,
            React.createElement(Text, { color: "green.600" },
                totalSelected,
                " / ",
                totalAvailable),
            React.createElement(Button, { bg: "green.500", color: "black", onClick: chain && chain.id === networkId
                    ? depositItemsContractCall
                    : handleSwitchNetwork, _hover: { bg: "green.400", transition: "background-color 0.2s" } }, chain && chain.id === networkId ? "Deposit" : "Switch Networks"))));
};
var Footer = function (_a) {
    var children = _a.children;
    return (React.createElement(Flex, { align: "center", justify: "space-between", flexShrink: 0, padding: "20px 30px", borderTopWidth: 1, borderColor: "blackAlpha.600" }, children));
};
