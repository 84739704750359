export var NETWORK = process.env.NETWORK;
export var ALCHEMY_KEY = process.env.ALCHEMY_KEY;
export var GAME_SERVER_URL = process.env.GAME_SERVER_URL;
export var BRIDGE_SERVER_URL = process.env.BRIDGE_SERVER_URL;
export var GEAR_SUBGRAPH_URL = process.env.GEAR_SUBGRAPH_URL;
export var VIDEO_URL = process.env.VIDEO_URL;
export var HOST = process.env.HOST;
export var LOADER_URL = process.env.LOADER_URL;
export var DATA_URL = process.env.DATA_URL;
export var FRAMEWORK_URL = process.env.FRAMEWORK_URL;
export var CODE_URL = process.env.CODE_URL;
export var STREAMING_ASSETS_URL = process.env.STREAMING_ASSETS_URL;
export var REACT_VERSION = process.env.REACT_VERSION;
