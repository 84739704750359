var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback, useRef } from "react";
import { AuthModal } from "./components/modals/AuthModal";
import { BridgeGearModal } from "./components/modals/BridgeGearModal";
import { TreasureAuthModal } from "./components/modals/TreasureAuthModal";
import { useModalContext } from "./contexts/ModalContext";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useAuthStatus } from "./hooks/useAuthStatus";
import { FixedContainer, UnityGameWrapper } from "./components/unity";
import IntroVideo from "./components/video/IntroVideo";
import { useChainInitiationContext } from "./contexts/ChainInitiationContext";
import BlackScreen from "./components/modals/BlackScreen";
import OverlayImage from "./components/modals/CrystalOverlay";
import "./index.css";
import "./style.css";
import { HOST, LOADER_URL, DATA_URL, FRAMEWORK_URL, CODE_URL, STREAMING_ASSETS_URL, BRIDGE_SERVER_URL, NETWORK, } from "@/env";
import { WebGLStorage } from "./webglcache";
var webGLStorage = new WebGLStorage();
var App = function () {
    var _a = useModalContext(), isAuthModalOpen = _a.isAuthModalOpen, isBridgeGearModalOpen = _a.isBridgeGearModalOpen, isInitiationModalOpen = _a.isInitiationModalOpen, isTreasureAuthModalOpen = _a.isTreasureAuthModalOpen, openAuthModal = _a.openAuthModal, closeAuthModal = _a.closeAuthModal, closeBridgeGearModal = _a.closeBridgeGearModal, closeInitiationModal = _a.closeInitiationModal, closeTreasureAuthModal = _a.closeTreasureAuthModal, isCrystalClicked = _a.isCrystalClicked, showOverlay = _a.showOverlay, setShowOverlay = _a.setShowOverlay;
    var _b = useState(false), isPlaymodeReady = _b[0], setPlaymodeReady = _b[1];
    var _c = useState(false), isIntroPlaying = _c[0], setIntroPlaying = _c[1];
    var authStatus = useAuthStatus();
    var isLoggedIn = authStatus === "authenticated";
    var _d = useState(null), initiationData = _d[0], setInitiationData = _d[1];
    var _e = useState(0), appBlackScreenOpacity = _e[0], setAppBlackScreenOpacity = _e[1];
    var setParams = useChainInitiationContext().setParams;
    var _f = useState(false), isRevalidationComplete = _f[0], setIsRevalidationComplete = _f[1];
    var revalidationPromise = useRef(null);
    var originalFetch = window.fetch;
    useEffect(function () {
        var checkForRevalidation = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, timestamp, cacheResponse, cacheData, last_updated, paths_to_revalidate, _i, paths_to_revalidate_1, path, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, webGLStorage.getAsset("last_update.txt")];
                    case 1:
                        result = _a.sent();
                        timestamp = result ? parseInt(String(result)) : 0;
                        // For testing purposes, always set timestamp to 0
                        timestamp = 0;
                        return [4 /*yield*/, fetch(BRIDGE_SERVER_URL + "/check_cache_updates/" + timestamp)];
                    case 2:
                        cacheResponse = _a.sent();
                        return [4 /*yield*/, cacheResponse.json()];
                    case 3:
                        cacheData = _a.sent();
                        last_updated = cacheData.last_updated;
                        paths_to_revalidate = cacheData.paths_to_revalidate;
                        console.log("Paths to revalidate: ", cacheData);
                        console.log("Revalidating " + paths_to_revalidate.length + " paths");
                        _i = 0, paths_to_revalidate_1 = paths_to_revalidate;
                        _a.label = 4;
                    case 4:
                        if (!(_i < paths_to_revalidate_1.length)) return [3 /*break*/, 7];
                        path = paths_to_revalidate_1[_i];
                        return [4 /*yield*/, webGLStorage.deleteAsset("/" + path)];
                    case 5:
                        _a.sent();
                        console.log("Deleted " + path + " from cache");
                        _a.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 4];
                    case 7:
                        console.log("Last update: " + timestamp);
                        return [4 /*yield*/, webGLStorage.storeAsset("last_update.txt", last_updated.toString())];
                    case 8:
                        _a.sent();
                        setIsRevalidationComplete(true);
                        return [3 /*break*/, 10];
                    case 9:
                        error_1 = _a.sent();
                        console.error("Error during revalidation:", error_1);
                        setIsRevalidationComplete(true); // Set to true even on error to prevent hanging
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        }); };
        revalidationPromise.current = checkForRevalidation();
    }, []);
    var transparentImage = new Image();
    transparentImage.src = "./1x1.png";
    function getMimeType(url) {
        if (url.endsWith(".js"))
            return "application/javascript";
        if (url.endsWith(".wasm"))
            return "application/wasm";
        if (url.endsWith(".png"))
            return "image/png";
        if (url.endsWith(".webm"))
            return "video/webm";
        if (url.endsWith(".jpeg"))
            return "image/jpeg";
        if (url.endsWith(".jpg"))
            return "image/jpeg";
        return "application/octet-stream"; // Default for .data and unknown types
    }
    function shouldInterceptRequest(url) {
        return (url.includes(LOADER_URL) ||
            url.includes(DATA_URL) ||
            url.includes(FRAMEWORK_URL) ||
            url.includes(CODE_URL) ||
            url.includes(STREAMING_ASSETS_URL) ||
            url.includes("DistorFinal_1.webm" ||
                url.includes("http://assets.knightsoftheether.com/DistorFinal_1.webm")));
    }
    var _g = useState(0), customLoadingProgression = _g[0], setCustomLoadingProgression = _g[1];
    var totalSizeRef = useRef(0);
    var downloadedSizeRef = useRef(0);
    function fetchAndStoreWebGLAsset(url) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var response, transparentImageResponse, transparentImageBuffer, reader, contentLength, receivedLength, chunks, _b, done, value, progression, arrayBuffer, position, _i, chunks_1, chunk, path, error_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, originalFetch(url)];
                    case 1:
                        response = _c.sent();
                        if (!(!response.ok ||
                            response.headers.get("content-type") == "application/xml")) return [3 /*break*/, 4];
                        // File doesn't exist, return transparent image as buffer
                        console.log("File doesn't exist, returning transparent image as buffer");
                        return [4 /*yield*/, fetch("./1x1.png")];
                    case 2:
                        transparentImageResponse = _c.sent();
                        return [4 /*yield*/, transparentImageResponse.arrayBuffer()];
                    case 3:
                        transparentImageBuffer = _c.sent();
                        return [2 /*return*/, transparentImageBuffer];
                    case 4:
                        reader = (_a = response.body) === null || _a === void 0 ? void 0 : _a.getReader();
                        contentLength = parseInt(response.headers.get("Content-Length") || "0", 10);
                        totalSizeRef.current += contentLength;
                        receivedLength = 0;
                        chunks = [];
                        _c.label = 5;
                    case 5:
                        if (!true) return [3 /*break*/, 7];
                        return [4 /*yield*/, (reader === null || reader === void 0 ? void 0 : reader.read())];
                    case 6:
                        _b = _c.sent(), done = _b.done, value = _b.value;
                        if (done)
                            return [3 /*break*/, 7];
                        chunks.push(value);
                        receivedLength += value.length;
                        downloadedSizeRef.current += value.length;
                        progression = Math.min(downloadedSizeRef.current / totalSizeRef.current, 1);
                        setCustomLoadingProgression(progression);
                        return [3 /*break*/, 5];
                    case 7:
                        arrayBuffer = new Uint8Array(receivedLength);
                        position = 0;
                        for (_i = 0, chunks_1 = chunks; _i < chunks_1.length; _i++) {
                            chunk = chunks_1[_i];
                            arrayBuffer.set(chunk, position);
                            position += chunk.length;
                        }
                        path = new URL(url).pathname;
                        return [4 /*yield*/, webGLStorage.storeAsset(path, arrayBuffer)];
                    case 8:
                        _c.sent();
                        console.log("Successfully stored ".concat(path, " in IndexedDB"));
                        return [2 /*return*/, arrayBuffer.buffer];
                    case 9:
                        error_2 = _c.sent();
                        console.error("Error fetching and storing webgl asset:", error_2);
                        throw error_2;
                    case 10: return [2 /*return*/];
                }
            });
        });
    }
    // Override the global fetch function
    useEffect(function () {
        var originalFetch = window.fetch;
        window.fetch = function (input, init) {
            return __awaiter(this, void 0, void 0, function () {
                var url, path, data, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            url = typeof input === "string"
                                ? input
                                : input instanceof URL
                                    ? input.href
                                    : input.url;
                            if (!shouldInterceptRequest(url)) return [3 /*break*/, 9];
                            if (!!isRevalidationComplete) return [3 /*break*/, 2];
                            console.log("Waiting for revalidation to complete before fetching:", url);
                            return [4 /*yield*/, revalidationPromise.current];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            console.log("Intercepting request to ".concat(url));
                            path = new URL(url).pathname;
                            _a.label = 3;
                        case 3:
                            _a.trys.push([3, 8, , 9]);
                            return [4 /*yield*/, webGLStorage.getAsset(path)];
                        case 4:
                            data = _a.sent();
                            if (!!data) return [3 /*break*/, 6];
                            console.log("Data not found in cache for ".concat(path, ", fetching and storing..."));
                            return [4 /*yield*/, fetchAndStoreWebGLAsset(url)];
                        case 5:
                            data = _a.sent();
                            return [3 /*break*/, 7];
                        case 6:
                            console.log("Found cached data for ".concat(path));
                            _a.label = 7;
                        case 7: return [2 /*return*/, new Response(data, {
                                status: 200,
                                headers: new Headers({
                                    "Content-Type": getMimeType(url),
                                }),
                            })];
                        case 8:
                            error_3 = _a.sent();
                            console.error("Error accessing IndexedDB or fetching data:", error_3);
                            return [3 /*break*/, 9];
                        case 9: return [2 /*return*/, originalFetch(input, init)];
                    }
                });
            });
        };
        return function () {
            window.fetch = originalFetch;
        };
    }, [isRevalidationComplete]);
    var _h = useUnityContext({
        loaderUrl: HOST + LOADER_URL,
        dataUrl: HOST + DATA_URL,
        frameworkUrl: HOST + FRAMEWORK_URL,
        codeUrl: HOST + CODE_URL,
        streamingAssetsUrl: HOST + STREAMING_ASSETS_URL,
    }), unityProvider = _h.unityProvider, loadingProgression = _h.loadingProgression, isLoaded = _h.isLoaded, sendMessage = _h.sendMessage, addEventListener = _h.addEventListener;
    var onUnityEvent = useCallback(function (data) {
        var json;
        try {
            json = JSON.parse(data);
            if (json.eventName == "logout") {
                window.koteWeb.removeAuthToken();
                window.location.reload();
            }
            if (json.eventName == "open-bridge") {
                window.koteWeb.openBridgeGearModal();
            }
            if (json.eventName == "initiate") {
                var data_1 = json.data;
                setInitiationData(data_1);
                setParams([
                    {
                        token: data_1.contract,
                        tokenId: data_1.tokenId,
                        signature: "", // You need to get the signature from somewhere
                        crestId: 0, // You need to get the crestId from somewhere
                        sigilId: 0, // You need to get the sigilId from somewhere
                    },
                ]);
                setShowOverlay(true);
            }
            else {
                console.log("Unknown message eventName " + json.eventName);
                console.log("10");
            }
        }
        catch (exception) {
            console.error(exception);
        }
        var unityMessage = json.eventName || undefined; // Assign an empty string if value is undefined
        if (unityMessage == undefined) {
            console.error("This UnityEvent shouldn't be undefined, contact support");
        }
        return unityMessage;
    }, []);
    useEffect(function () {
        addEventListener("GetUnityMessage", onUnityEvent);
        return function () {
            removeEventListener("GetUnityMessage", onUnityEvent);
        };
    }, [addEventListener, removeEventListener, onUnityEvent]);
    useEffect(function () {
        if (showOverlay) {
            setAppBlackScreenOpacity(1);
        }
        else {
            setAppBlackScreenOpacity(0);
        }
    }, [showOverlay]);
    var onPressLogin = function () {
        console.log("Check this out connor!");
        window.koteWeb.openAuthModal();
    };
    var onPressPlay = function () {
        setIntroPlaying(true);
        console.log("press play");
    };
    //moved onPlay functionlity here to ensure video plays first
    var handleVideoEnd = function () {
        setIntroPlaying(false);
        setPlaymodeReady(true);
        var token = window.koteWeb.getAuthToken() || "undefined"; // Assign an empty string if value is undefined
        if (token == "undefined") {
            console.error("This token shouldn't be undefined, contact support");
        }
        sendMessage("LoadingManager", "Login", token);
    };
    var onRefresh = function () {
        console.log("[Web] Called refresh-armory from javascript to unity");
        sendMessage("WebBridge", "OnWebMessage", "refresh-armory");
    };
    var handleConfirmClick = function () {
        setTimeout(function () {
            setShowOverlay(true);
        }, 2000); // Delay of 2 seconds
    };
    var isPlayButtonDisabled = !isLoaded && isLoggedIn;
    var isLoginButtonDisabled = !isLoaded;
    var isModalBlocking = isAuthModalOpen || isBridgeGearModalOpen;
    var renderUnityGame = function () { return (React.createElement(UnityGameWrapper, null,
        React.createElement("div", { style: { display: isIntroPlaying ? "none" : "block" } },
            React.createElement(Unity, { className: "unity-game", unityProvider: unityProvider })),
        isIntroPlaying && React.createElement(IntroVideo, { onEnd: handleVideoEnd }))); };
    var renderAuthModal = function () { return (React.createElement(AuthModal, { isOpen: isAuthModalOpen, onClose: closeAuthModal })); };
    var renderBridgeGearModal = function () { return (React.createElement(BridgeGearModal, { isOpen: isBridgeGearModalOpen, onClose: closeBridgeGearModal, openAuthModal: openAuthModal, onRefresh: onRefresh })); };
    var renderTreasureAuthModal = function () { return (React.createElement(TreasureAuthModal, { isOpen: isTreasureAuthModalOpen, onClose: closeTreasureAuthModal })); };
    var renderBackgroundImage = function () { return React.createElement("div", { className: "background-image" }); };
    var renderPlayButton = function () {
        var isTestnet = NETWORK === "testnet";
        var allowPlay = isTestnet || process.env.REACT_APP_ALLOW_PLAY === "true";
        var password = process.env.REACT_APP_PLAY_PASSWORD;
        var buttonText = allowPlay ? "PLAY" : "Come back later!";
        var buttonAction = allowPlay
            ? onPressPlay
            : function () {
                var userPassword = window.prompt("Enter password to play");
                if (userPassword === password) {
                    onPressPlay();
                }
                else {
                    alert("Incorrect password");
                }
            };
        return (React.createElement("div", { className: "custom-button-container ".concat(isPlayButtonDisabled || !allowPlay ? "disabled" : ""), onClick: function () { return !isPlayButtonDisabled && buttonAction(); } },
            React.createElement("div", { className: "custom-button ".concat(isPlayButtonDisabled || !allowPlay ? "disabled" : "") },
                React.createElement("h1", null, buttonText)),
            (isPlayButtonDisabled || !allowPlay) && (React.createElement("div", { className: "disabled-overlay" }))));
    };
    var renderLoginButton = function () { return (React.createElement("div", { className: "custom-button-container ".concat(isLoginButtonDisabled ? "disabled" : ""), onClick: function () { return !isLoginButtonDisabled && onPressLogin(); } },
        React.createElement("div", { className: "custom-button ".concat(isLoginButtonDisabled ? "disabled" : "") },
            React.createElement("h1", null, "LOGIN")),
        isLoginButtonDisabled && React.createElement("div", { className: "disabled-overlay" }))); };
    var renderButtonsContainer = function () { return (React.createElement("div", { className: "buttons-container", style: { display: isIntroPlaying ? "none" : "" } },
        isLoggedIn && renderPlayButton(),
        renderLoginButton())); };
    var renderLoadingText = function () { return (React.createElement("h1", { className: "loading-application-text" },
        "Loading Unity...",
        " ",
        Math.min(Math.round(customLoadingProgression * 100), 100),
        "%")); };
    var handleFullscreen = function () {
        var element = document.documentElement;
        if (document.fullscreenElement !== null) {
            // Website is in fullscreen
            document.exitFullscreen();
        }
        else {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            }
        }
    };
    return (React.createElement(FixedContainer, null,
        React.createElement(BlackScreen, { opacity: appBlackScreenOpacity }),
        renderUnityGame(),
        showOverlay && ( // App.tsx
        React.createElement(OverlayImage, { initiationData: initiationData, handleConfirmClick: handleConfirmClick, sendMessage: sendMessage })),
        renderAuthModal(),
        renderBridgeGearModal(),
        renderTreasureAuthModal(),
        !isPlaymodeReady && renderBackgroundImage(),
        !isPlaymodeReady && !isModalBlocking && renderButtonsContainer(),
        !isLoaded && renderLoadingText(),
        React.createElement("div", { id: "spacing", className: "spacing" }),
        React.createElement("div", { className: "unity-fullscreen-button", id: "unity-fullscreen-button", onClick: handleFullscreen })));
};
export default App;
